<template>
  <div class="banner">
    <v-img
      :src="images[currentIndex]"
      class="align-center"
      v-observe-visibility="
        (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')
      "
    >
      <div
        class="d-flex align-center banner-text selino"
        :class="{
          'mx-8 my-6': $vuetify.breakpoint.mdAndUp,
          'mx-4 my-10': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          class="banner-text-wrapper"
          v-observe-visibility="
            (isVisible, entry) =>
              visibilityChanged(isVisible, entry, 'fade-left')
          "
        >
          <span class="non-outlined-title">Semua tentang</span>
          <div class="d-flex">
            <span class="outlined-title">Stiker</span>
            <span class="non-outlined-title">dan</span>
            <span class="outlined-title">Label</span>
          </div>
          <p class="banner-desc my-2">
            Solusi tepat untuk cetak stiker dan label murah, berkualitas, dan
            terpercaya. Menggunakan bahan sesuai dengan aplikasi. Pengiriman ke
            seluruh Indonesia.
          </p>
          <router-link to="/products" exact>
            <v-btn
            class="main-button mx-2"
            v-observe-visibility="
              (isVisible, entry) =>
                visibilityChanged(isVisible, entry, 'fade-right')
            "
          >
            SHOP NOW
          </v-btn>
          </router-link>

        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
  methods: {
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    changeImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
  },
  data() {
    return {
      images: [
        require("../assets/main_bg.png"),
        require("../assets/main_bg_backup.png"),
      ],
      currentIndex: 0,
    };
  },
  mounted() {
    this.intervalId = setInterval(this.changeImage, 4000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style>
.banner {
  background-color: #f8f8f8;
}
.banner-text {
  font-size: clamp(30px, 4.2vw, 200px) !important;
  height: inherit !important;
}
.non-outlined-title {
  padding: 0vw 1.2vw;
}
.outlined-title {
  border-radius: 1000px;
  border: 1px #114aa4 solid;
  justify-content: center;
  align-items: center;
  padding: 0vw 1.2vw;
  color: #114aa4;
}
.banner-desc {
  font-family: "Standerd", sans-serif;
  font-weight: 300;
  font-size: clamp(16px, 1.9vw, 50px) !important;
  padding: 0vw 1.2vw;
  max-width: 45vw;
}
.main-button {
  font-family: "Standerd", sans-serif;
  font-weight: 300;
  background-color: #131313 !important;
  color: white !important;
  font-size: clamp(16px, 1.2vw, 42px) !important;
  height: clamp(40px, 3.5vw, 60px) !important;
  border-radius: 1000px;
  padding: 1vw 4vw !important;
}

.main-button-outlined {
  font-family: "Standerd", sans-serif;
  font-weight: 300;
  background-color: white !important;
  border: 1px #131313 solid;
  color: #131313 !important;
  font-size: clamp(16px, 1.5vw, 50px) !important;
  height: clamp(40px, 3.5vw, 60px) !important;
  border-radius: 1000px;
  padding: 1vw 4vw !important;
}
#home-banner-image:hover {
}
</style>

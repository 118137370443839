<template>
  <div class="banner border-top border-left border-right">
    <v-img :src="require('../assets/main_bg_backup.png')" class="align-center">
      <div class="d-flex align-center banner-text selino mx-8 my-6">
        <div class="banner-text-wrapper">
          <span
            class="non-outlined-title"
            v-observe-visibility="
              (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')
            "
            >We Use</span
          >
          <div class="d-flex">
            <span
              class="outlined-title"
              v-observe-visibility="
                (isVisible, entry) =>
                  visibilityChanged(isVisible, entry, 'fade-top')
              "
              >Best</span
            >
            <span
              class="outlined-title"
              v-observe-visibility="
                (isVisible, entry) =>
                  visibilityChanged(isVisible, entry, 'fade-bottom')
              "
              >Quality</span
            >
          </div>
          <span
            class="non-outlined-title"
            v-observe-visibility="
              (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')
            "
            >Materials</span
          >
          <!-- <v-row>
            <v-col cols="4"><p class="banner-desc">Test</p></v-col>
            <v-col cols="4"><p class="banner-desc">Test</p></v-col>
            <v-col cols="4"><p class="banner-desc">Test</p></v-col>
          </v-row> -->
        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  methods: {
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
  },
};
</script>

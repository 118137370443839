const state = {
  apiKey: 'e556d445-ca4a-4b00-84a9-d553d954abf7',
  token: '',
  baseUrl: 'https://api.cetaklabel.id',
  products: [],
  featuredProducts: [],
  checkouts: [],
  user: {},
  maxTitleLines: 1, // new state property
};

const mutations = {
  setApiKey(state, apiKey) {
    state.apiKey = apiKey;
  },
  setToken(state, token) {
    state.token = token;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setFeaturedProducts(state, featuredProducts) {
    state.featuredProducts = featuredProducts;
  },
  setCheckouts(state, checkouts) {
    state.checkouts = checkouts;
  },
  setUser(state, user) {
    state.user = user;
  },
  setMaxTitleLines(state, maxLines) {
    state.maxTitleLines = maxLines;
  }
};

const actions = {
  setApiKey({ commit }, apiKey) {
    commit('setApiKey', apiKey);
  },
  setToken({ commit }, token) {
    commit('setToken', token);
  },
  setProducts({ commit }, products) {
    commit('setProducts', products);
  },
  setFeaturedProducts({ commit }, featuredProducts) {
    commit('setFeaturedProducts', featuredProducts);
  },
  setCheckouts({ commit }, checkouts) {
    commit('setCheckouts', checkouts);
  },
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  setMaxTitleLines({ commit }, maxLines) {
    commit('setMaxTitleLines', maxLines);
  }
};

const getters = {
  apiKey: state => state.apiKey,
  token: state => state.token,
  baseUrl: state => state.baseUrl,
  products: state => state.products,
  featuredProducts: state => state.featuredProducts,
  checkouts: state => state.checkouts,
  user: state => state.user,
  maxTitleLines: state => state.maxTitleLines,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div>
    <div class="d-flex best-seller-wrapper justify-center mb-10">
      <div class="main-title-outline main-title selino" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'bounce')
        ">
        OUR BEST SELLER
      </div>
    </div>
    <v-row class="d-flex" :class="{
      'mx-10 px-10': $vuetify.breakpoint.mdAndUp,
      'mx-4 px-4': !$vuetify.breakpoint.mdAndUp,
    }">
      <ProductCard :smSize="6" :mdSize="3" :lgSize="3" :isBestSeller="true" v-for="(product, index) in items"
        v-bind:key="index" :productName="product.name" :startFrom="product.startFrom" :images="product.images" :productId="product.id"/>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ProductCard from "../components/ProductCard";

export default {
  name: "BestSellerSection",
  data() {
    return {
      items: []
    }
  },
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts"]),
  },
  components: {
    ProductCard,
  },
  methods: {
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    async getProducts() {
      const url = `${this.baseUrl}/product`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.statusCode == 200) {
          const products = response.data.data.map((product) => ({
            ...product,
            firstImage: product.images[0],
            secondImage: product.images.length > 1 ? product.images[1] : product.images[0],
            startFrom: product.specifications
              .filter(spec => spec.type === "PRICE" && spec.options && spec.options.length > 0) 
              .map(spec => spec.options[0].price)[0] || null // Map to get all prices and take the first, fallback to null if none
          }));
          console.log("modified", products)
          this.items = products.filter((it) => it.isFeatured);
        }
      } catch (error) {
        console.error('Error getting list product:', error);
      }
    },
  },
  mounted() {
    this.getProducts()
  }
};
</script>

<style>
p {
  margin-bottom: 0px !important;
}

.best-seller-wrapper {
  margin-top: -1vw !important;
}
</style>

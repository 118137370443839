<template>
<v-container>
  <v-container fluid class="card-in-image-container">
    <v-layout align-center justify-center>
      <v-flex xs12>
        <div
          class="absolute-center px-12 py-12 d-flex flex-column text-center"
          :class="{
            'mt-10 pt-10 pb-10': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <!-- Your content here -->
          <h1 class="khula-bold">cetaklabel.id</h1>
          <p class="standerd-light">Semua tentang stiker dan label.</p>
          <p class="stander py-4">Register</p>
          <v-text-field label="Email" v-model="userData.email" name="email" required outlined class="min-width-field"></v-text-field>
          <v-text-field label="Name" v-model="userData.name" name="name" required outlined></v-text-field>
          <v-text-field label="Company Name" v-model="userData.companyName" name="company" outlined></v-text-field>
          <v-text-field label="Phone Number" v-model="userData.phoneNumber" name="phone" type="text" placeholder="+62 ..." required outlined></v-text-field>
          <v-text-field label="Password" type="password" v-model="userData.password" name="password" required outlined></v-text-field>
          <v-text-field label="Password Confirmation" type="password" v-model="userData.passConfirm" outlined></v-text-field>
          <p class="standerd-light">
            Sudah punya akun?
            <span class="text-blue"><router-link to="/login" exact><u>Login</u></router-link></span>
          </p>
          <br />
          <v-alert v-if="alertMessage" type="error" class="p-4">
            {{ alertMessage }}
          </v-alert>
          <div class="align-center">
            <v-btn class="main-button mx-2" @click="register"> Register</v-btn>
          </div>

          <v-dialog v-model="dialog" persistent max-width="50%">
            <v-card>
              <v-card-title class="standerd-light">Selamat Bergabung! </v-card-title>
              <v-card-text class="standerd-light">Pendaftaran Berhasil! Klik OK untuk login.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
  <FooterSection />
</v-container>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl"]),
  },
  components: {
    FooterSection,
  },
  data() {
    return {
      userData: {
        email: null,
        name: null,
        companyName: null,
        password: null,
        passConfirm: null,
        phoneNumber: null,
        role: "END_USER",
      },
      alertMessage: null, // Add this line
      dialog: false,
    };
  },
  methods: {
    handleDialogOk() {
      this.dialog = false; // Hide the dialog
      this.$router.push('/login'); // Navigate to the homepage
    },
    async register() {

      if(this.userData.email == ''){
        this.alertMessage = "Email cannot be empty"
      } else if (this.userData.name == '') {
        this.alertMessage = "Name cannot be empty"
      } else if (this.userData.phoneNumber == '') {
        this.alertMessage = "Phone number cannot be empty"
      } else if (this.userData.password == '') {
        this.alertMessage = "Password cannot be empty"
      } else if (this.userData.passConfirm == '') {
        this.alertMessage = "Password cannot be empty"
      } else if (this.userData.passConfirm != this.userData.password) {
        this.alertMessage = "Password not match"
      } else {
        const url = `${this.baseUrl}/auth/register`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
      };

      try {
        if (this.userData.phoneNumber.startsWith("0")) {
          this.userData.phoneNumber = "+62" + this.userData.phoneNumber.slice(1);
        }

        const response = await axios.post(url, this.userData, { headers });
        if(response.data.statusCode === 201) {
          this.dialog = true;
        } else {
          this.alertMessage = response.data.message;
        }
      } catch (error) {
        this.alertMessage = error.response.data.message;
      }
      }
    },
  },
};
</script>

<style scoped>
.card-in-image-container {
  background-image: url('../assets/main_bg_backup.png'); /* Set background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* No repeating the background */
  background-color: #f8f8f8;
  height: 120vh; /* corrected 100dvh to 100vh */
  overflow: hidden;
}

.absolute-center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); /* Adjusted for a subtle effect */
}

.text-blue {
  color: blue;
}

.min-width-field {
  min-width: 40vw;
}
</style>


<template>
  <v-container fluid class="main-background">
    <v-container>
      <div class="content-container standerd-light">
        <h1 class="selino mb-2">{{ user.name }}</h1>
        <v-row align="center" class="info-row">
          <v-col cols="3">Company Name:</v-col>
          <v-col class="standerd-bold">{{ user.companyName }}</v-col>
        </v-row>

        <v-row align="center" class="info-row">
          <v-col cols="3">Email:</v-col>
          <v-col class="standerd-bold">{{ user.email }}</v-col>
        </v-row>

        <v-row align="center" class="info-row mb-4">
          <v-col cols="3">Phone Number:</v-col>
          <v-col class="standerd-bold">{{ user.phoneNumber }}</v-col>
        </v-row>

        <v-btn
            class="main-button"
            v-observe-visibility="
              (isVisible, entry) =>
                visibilityChanged(isVisible, entry, 'fade-right')
            "
            @click="logout"
          >
            Logout
          </v-btn>
      </div>

      <div class="content-container standerd-light">
        <h1 class="selino">My Orders</h1>
        <v-data-table :headers="headers" :items="checkoutItems" :search="search">
          <template v-slot:top>
            <v-text-field v-model="search" class="pa-2" label="Search"></v-text-field>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mr-2" :to="`/checkout/${item.id}`">Detail</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <FooterSection />
  </v-container>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
  name: "OrderListPage",
  components: {
    FooterSection,
  },
  data() {
    return {
      checkoutItems: [],
      headers: [
        { text: 'Tanggal Dibuat', value: 'created', sortable: true },
        // { text: 'Last Updated', value: 'lastUpdated', sortable: true },
        { text: 'No Referensi', value: 'refNumber', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        // { text: 'Products', value: 'formattedProductsForDisplay', sortable: true },
        { text: 'Action', value: 'action', sortable: false },
      ],
      search: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts", "user"])
  },
  methods: {
    logout(){
      this.setToken('')
      this.setCheckouts([])
      this.setUser({})
      this.$router.push("/login");
    },
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    ...mapActions('auth', ['setProducts', 'setFeaturedProducts', 'setCheckouts', 'setToken', 'setUser']),
    translateStatus(status) {
      const statusMap = {
        'WAITING_PAYMENT_PROOF': 'Menunggu Pembayaran',
        'WAITING_VERIFICATION': 'Pembayaran Sedang Diverifikasi',
        'PAYMENT_PROOF_REJECTED': 'Pesanan Dibatalkan',
        'PAYMENT_PROOF_APPROVED': 'Pesanan Diproses',
        'COMPLETED': 'Pesanan Dikirim'
      };
      return statusMap[status] || status; // Return original status if not found in map
    },
    async getCheckoutItems() {
      const url = `${this.baseUrl}/checkout`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
        'Authorization': `token ${this.token}`
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.statusCode == 200) {
          this.checkoutItems = response.data.data.map(
            (it) => ({
              ...it,
              // numOfItems: it.checkoutItems.length,
              // formattedProductsForDisplay: it.checkoutItems.map(item => item.order.productName).join("\n"),
              status: this.translateStatus(it.status)
            })
          ).sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
          });
          this.setCheckouts(this.checkoutItems)
          console.log("checkoutItems", this.checkoutItems)
        }
      } catch (error) {
        console.error('Error getting list checkouts:', error);
      }
    },
  },
  mounted() {
    if (this.token === '') {
      this.$router.push('/login');
    } else {
      this.getCheckoutItems()
    }
  }
};
</script>

<style>
.main-background {
  background-color: #f8f8f8;
  height: 100vh;
  /* corrected 100dvh to 100vh for valid CSS */
  overflow: auto;
  background-image: url('../assets/main_bg_backup.png');
  /* Path to your background image */
  background-size: cover;
  /* Cover the entire container */
  background-position: center;
  /* Center the background image */
  background-repeat: repeat;
  /* No repeating the background */
}

.content-container {
  background-color: white;
  border-radius: 10px;
  border: 1px #e0dfe4 solid;
  box-shadow: 0 0 100px 0 rgba(14, 65, 148, 0.2);
  padding: 3vw;
  margin: 3vw;
}


.info-row .v-col {
  padding-top: 0;
  /* Reduces space within the columns if needed */
  padding-bottom: 0;
}

.text-end {
  text-align: right;
  /* Ensures labels are right-aligned */
}
</style>

<template>
  <v-col :md="mdSize" :sm="smSize" :lg="lgSize" class="mt-4"
    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')">
    <div class="main-container">
      <div class="product-container mx-3" @click="navigateToAnotherPage">
        <div class="product-content px-3 pa-3">
          <h2 class="product-title standerd-light">{{ productName }}</h2>
          <h3 class="product-price standerd-light">
            Start from <span class="standerd-bold">{{ formatRupiah(startFrom) }}</span> /rol
          </h3>
          <v-carousel hide-delimiters cycle height="200px" class="mt-2 mb-2" @mouseenter="isHover = true"
            @mouseleave="isHover = false">
            <v-carousel-item v-for="(src, index) in images" :key="index">
              <div class="carousel-image" :style="{ backgroundImage: 'url(' + getImageUrl(src) + ')' }"></div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <p class="bs-tag standerd-bold px-2 pr-4 py-1" v-if="isBestSeller">BEST SELLER</p>
      </div>
      <p class="product-atc standerd-bold py-2 text-center mx-3" @click="navigateToAnotherPage">ADD TO CART</p>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    mdSize: Number,
    smSize: Number,
    lgSize: Number,
    productId: String,
    isBestSeller: {
      type: Boolean,
      default: false,
    },
    productName: {
      type: String,
      default: "",
    },
    startFrom: {
      type: Number,
      default: 0,
    },
    images: {
      type: Array,
      default: () => ["https://media.istockphoto.com/id/1399588872/vector/corrupted-pixel-file-icon-damage-document-symbol-sign-broken-data-vector.jpg?s=612x612&w=0&k=20&c=ffG6gVLUPfxZkTwjeqdxD67LWd8R1pQTIyIVUi-Igx0="]
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    
  },
  methods: {
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    getImageUrl(path) {
      if (path.includes("http")) {
        return path;
      }
      return "https://firebasestorage.googleapis.com/v0/b/cetaklabel2024-ad87c.appspot.com/o/" + path + "?alt=media";
    },
    navigateToAnotherPage() {
      this.$router.push("/product/" + this.productId);
    },
    formatRupiah(num) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
      }).format(num);
    },
  },
  mounted() {
  }
};
</script>

<style>
.product-container {
  border: 1px #131313 solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it takes full height of the parent */
  justify-content: space-between; /* Distribute space evenly */
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it takes full height of the parent */
  justify-content: space-between; /* Distribute space evenly */
}

.product-content {
  flex-grow: 1; /* Allow it to grow and fill the available space */
}

.product-title {
  font-size: clamp(20px, 1.36vw, 100px) !important;
}

.product-price {
  font-size: clamp(20px, 1.36vw, 100px) !important;
}

.bs-tag {
  color: white;
  background-color: #114aa4;
  width: fit-content !important;
  border-top-right-radius: 10px;
  font-size: clamp(14px, 1.2vw, 60px) !important;
}

.product-atc:hover {
  cursor: pointer;
}

.product-atc {
  color: white;
  background-color: #131313;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: clamp(14px, 1.2vw, 60px) !important;
}

.product-atc:hover {
  background-color: #262626;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.product-container:hover {
  box-shadow: 0 0 20px 0 rgba(14, 65, 148, 0.2);
  margin-top: 10px;
}

.carousel-image {
  height: 200px;
  width: 250px; /* Maintain 5:4 aspect ratio */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto; /* Center horizontally */
}


.v-carousel .v-btn {
  display: none;
}

.v-carousel:hover .v-btn {
  display: flex;
}
</style>

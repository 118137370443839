import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutUsPage from '../views/AboutUsPage.vue'
import CheckoutCartPage from '../views/CheckoutCartPage.vue'
import CheckoutListPage from '../views/CheckoutListPage.vue'
import CheckoutPage from '../views/CheckoutPage.vue'
import ProductDetailPage from '../views/ProductDetailPage.vue'
import ProductListPage from '../views/ProductListPage.vue'
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import ForgotPasswordPage from '../views/ForgotPasswordPage.vue'
import ConfirmForgotPasswordPage from '../views/ConfirmForgotPasswordPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/products',
    name: 'productlist',
    component: ProductListPage
  },
  {
    path: '/about-us',
    name: 'aboutus',
    component: AboutUsPage
  },
  {
    path: '/product/:id',
    name: 'productdetail',
    component: ProductDetailPage
  },
  {
    path: '/cart',
    name: 'cart',
    component: CheckoutCartPage
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: CheckoutPage
  },
  {
    path: '/my-order',
    name: 'orderlist',
    component: CheckoutListPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },
  {
    path: '/forgot-password',
    name: 'forgotpassword',
    component: ForgotPasswordPage
  },
  {
    path: '/confirm-forgot-password',
    name: 'confirmforgotpassword',
    component: ConfirmForgotPasswordPage
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('v-img',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      (isVisible, entry) => _vm.visibilityChanged(isVisible, entry, 'fade')
    ),expression:"\n      (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')\n    "}],staticClass:"align-center",attrs:{"src":_vm.images[_vm.currentIndex]}},[_c('div',{staticClass:"d-flex align-center banner-text selino",class:{
        'mx-8 my-6': _vm.$vuetify.breakpoint.mdAndUp,
        'mx-4 my-10': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
          (isVisible, entry) =>
            _vm.visibilityChanged(isVisible, entry, 'fade-left')
        ),expression:"\n          (isVisible, entry) =>\n            visibilityChanged(isVisible, entry, 'fade-left')\n        "}],staticClass:"banner-text-wrapper"},[_c('span',{staticClass:"non-outlined-title"},[_vm._v("Semua tentang")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"outlined-title"},[_vm._v("Stiker")]),_c('span',{staticClass:"non-outlined-title"},[_vm._v("dan")]),_c('span',{staticClass:"outlined-title"},[_vm._v("Label")])]),_c('p',{staticClass:"banner-desc my-2"},[_vm._v(" Solusi tepat untuk cetak stiker dan label murah, berkualitas, dan terpercaya. Menggunakan bahan sesuai dengan aplikasi. Pengiriman ke seluruh Indonesia. ")]),_c('router-link',{attrs:{"to":"/products","exact":""}},[_c('v-btn',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
            (isVisible, entry) =>
              _vm.visibilityChanged(isVisible, entry, 'fade-right')
          ),expression:"\n            (isVisible, entry) =>\n              visibilityChanged(isVisible, entry, 'fade-right')\n          "}],staticClass:"main-button mx-2"},[_vm._v(" SHOP NOW ")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
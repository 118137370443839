<template>
<v-container>
    <v-container class="card-in-image-container-about">
        <div class="about-card mx-12 my-12 px-8 py-8">
            <!-- <v-row class="mb-8" justify="center">
                <v-col cols="12">
                    <h2 class="khula-semibold text-blue text-center">cetaklabel.id</h2>
                </v-col>
            </v-row> -->

            <v-row v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')
                ">
                <v-col cols="12">
                    <h1 class="selino text-blue">Tentang Kami</h1>
                    <p class="standerd-light">
                        Cetaklabel.id adalah mitra terpercaya Anda dalam penyediaan solusi cetak sticker dan label yang
                        berkualitas tinggi dan harga terjangkau. Kami berkomitmen untuk menyediakan produk yang tidak
                        hanya memenuhi, tapi melampaui ekspektasi pelanggan kami di seluruh Indonesia.
                    </p>
                    <br>
                </v-col>

            </v-row>

            <v-row class="values-section" v-observe-visibility="(isVisible, entry) =>
                visibilityChanged(isVisible, entry, 'fade-left')
                ">
                <v-col cols="12" class="standerd-light">
                    <h3 class="standerd-light text-blue">Mengapa Memilih Kami?</h3>
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-check-circle-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="standerd-semibold">Kualitas Terjamin</v-list-item-title>
                                <v-list-item-subtitle>Kami menggunakan bahan-bahan terbaik yang sesuai dengan kebutuhan
                                    aplikasi Anda, memastikan bahwa setiap produk kami adalah representasi dari kualitas
                                    yang kami janjikan.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-cash-multiple</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="standerd-semibold">Harga Kompetitif</v-list-item-title>
                                <v-list-item-subtitle>Dapatkan harga terbaik tanpa mengorbankan kualitas. Kami percaya
                                    bahwa kualitas tinggi tidak harus selalu dengan harga yang
                                    tinggi.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-truck-delivery-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="standerd-semibold">Pengiriman Ke Seluruh Indonesia</v-list-item-title>
                                <v-list-item-subtitle>Lokasi Anda bukan halangan. Kami siap mengirimkan produk-produk
                                    berkualitas kami ke seluruh wilayah Indonesia dengan jaminan keamanan dan
                                    kecepatan.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-right')
                    ">
                    <h3 class="standerd-light text-blue">Our Gallery</h3>
                    <v-row>
                        <!-- Placeholder for gallery images -->
                        <v-col cols="12" sm="6" md="4">
                            <v-img :src="require('@/assets/img1.jpeg')" aspect-ratio="1.6"
                                class="grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-img :src="require('@/assets/img2.jpeg')" aspect-ratio="1.6"
                                class="grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-img :src="require('@/assets/img3.jpeg')" aspect-ratio="1.6"
                                class="grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

    </v-container>
    <FooterSection />
</v-container>
</template>

<script>
import FooterSection from "../components/FooterSection";
export default {
    name: 'AboutUsPage',
    components: {
    FooterSection,
  },
    methods: {
        visibilityChanged(isVisible, entry, animationType) {
            let domElement = entry.target;
            if (isVisible) {
                domElement.classList.add(animationType);
            } else {
                domElement.classList.remove(animationType);
            }
        },
    }
}
</script>

<style scoped>
.card-in-image-container-about {
    background-image: url('../assets/main_bg_backup.png');
    /* Set background image */
    background-size: cover;
    /* Cover the entire container */
    background-position: center;
    /* Center the background image */
    background-repeat: no-repeat;
    /* No repeating the background */
    background-color: #f8f8f8;
    overflow: auto;
}

.about-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /* Adjusted for a subtle effect */
}

.values-section i {
    color: #114aa4;
}
</style>
<template>
  <div class="container-holder">
    <v-row>
      <v-col cols="8" class="upper-left-container">
        <v-img
          :src="require('../assets/img2.jpeg')"
          lazy-src="https://www.tlbx.app/200.svg"
          class="gallery-image border-top border-left"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
      </v-col>
      <v-col cols="4" class="upper-right-container">
        <v-img
          :src="require('../assets/img3.jpeg')"
          lazy-src="https://www.tlbx.app/200.svg"
          class="gallery-image border-top border-left border-right"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="lower-container">
        <v-img
          :src="require('../assets/img1.jpeg')"
          lazy-src="https://www.tlbx.app/200.svg"
          class="gallery-image border-top border-left border-right"
        >
          <!-- <div class="best-seller-title selino gallery-title">OUR GALLERY</div> -->
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require("../assets/i1.png"),
        require("../assets/i2.png"),
        require("../assets/i3.png"),
      ],
      currentIndex: 0,
    };
  },
};
</script>

<style>
.upper-left-container {
  /* background-color: red; */
  max-height: clamp(100px, 40vh, 700px) !important;
  overflow: hidden;
}
.upper-right-container {
  background-color: yellow;
  max-height: clamp(100px, 40vh, 700px) !important;
  overflow: hidden;
}
.lower-container {
  background-color: green;
  max-height: clamp(100px, 40vh, 700px) !important;
  overflow: hidden;
}
.gallery-title {
  margin-top: -3vw;
}
.gallery-image {
  width: 100%;
  min-height: clamp(100px, 40vh, 700px) !important;
}
</style>

<template>
  <v-container>
    <v-container class="d-none d-md-flex">
      <v-row class="d-flex flex-wrap">
        <v-col cols="8" class="photos-container">
          <v-carousel cycle height="85dvh" hide-delimiter-background hide-delimiters show-arrows="hover">
            <v-carousel-item v-for="(image, i) in product.images" :key="i">
              <div class="image-container">
                <img :src="getImageUrl(image)" alt="Product image" class="responsive-image">
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="4" class="detail-container" :class="{
          'px-8 py-6': $vuetify.breakpoint.mdAndUp,
          'px-2 py-2': !$vuetify.breakpoint.mdAndUp,
        }">
          <p class="main-title standerd-bold detail-text">
            {{ product.name }}
          </p>
          <p class="product-description">
            {{ product.description }}
          </p>
          <p class="product-description mt-4">
            Start from <span class="standerd-bold">{{ product.startFrom }}</span> / pc
          </p>
          <br />
          <hr />
          <br />

          <v-select :items="priceSpec.options" :label="priceSpec.name" outlined v-model="selectedPriceSpec"
            item-text="alternateName" item-value="id"></v-select>
          <v-text-field label="Qty" type="number" hide-details="auto" outlined class="mb-6"
            v-model="qty"></v-text-field>
          <v-select :items="deliverySpec.options" :label="deliverySpec.name" outlined v-model="selectedDeliverySpec"
            item-text="alternateName" item-value="id"></v-select>
          <div v-for="(spec, index) in otherSpecs" v-bind:key="index">
            <v-select :items="spec.options" :label="spec.name" outlined v-model="selectedOtherSpec[index]"
              item-text="name" item-value="id"></v-select>
          </div>
          <!-- <v-textarea label="File" type="text" hide-details="auto" outlined class="mb-6" v-model="imageLinks"
            placeholder="Link file. Apabila lebih dari satu link, pisahkan dengan simbol koma"></v-textarea> -->

          <v-textarea label="Alamat Pengiriman" type="text" hide-details="auto" outlined class="mb-6"
            v-model="address"></v-textarea>

          <v-textarea label="Catatan" type="text" hide-details="auto" outlined class="mb-6"
            v-model="notes"></v-textarea>

          <br />
          <hr />
          <p class="product-description my-4" v-if="!isAbleToCalculateFinalPrice">
            Pilih spesifikasi produk untuk melihat harga akhir
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Harga Produk: <b>{{ totalProductPrice }}</b>
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Biaya Pengiriman: <b>{{ totalDeliveryPrice }}</b>
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Total: <b>{{ totalPrice }}</b>
          </p>
          <v-alert v-if="alertMessage" type="error" class="p-4 mt-4">
            {{ alertMessage }}
          </v-alert>
          <v-btn class="main-button mx-2" v-observe-visibility="(isVisible, entry) =>
            visibilityChanged(isVisible, entry, 'fade-right')
            " @click="createOrder">Add To Cart</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="d-md-none">
      <v-row class="d-flex flex-wrap">
        <v-col cols="12" class="photos-container border-all">
          <v-carousel cycle height="85dvh" hide-delimiter-background show-arrows="hover">
            <v-carousel-item v-for="(image, i) in product.images" :key="i">
              <div class="image-container">
                <img :src="getImageUrl(image)" alt="Product image" class="responsive-image">
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-wrap">
        <v-col cols="12" class="detail-container px-8 py-8" style="max-height: unset">
          <p class="main-title standerd-bold detail-text">
            {{ product.name }}
          </p>
          <p class="product-description">
            {{ product.description }}
          </p>
          <p class="product-description mt-4">
            Start from <span class="standerd-bold">500</span> / pc
          </p>
          <br />
          <hr />
          <br />
          <v-select :items="priceSpec.options" :label="priceSpec.name" outlined v-model="selectedPriceSpec"
            item-text="alternateName" item-value="id"></v-select>
          <v-text-field label="Qty" type="number" hide-details="auto" outlined class="mb-6"
            v-model="qty"></v-text-field>
          <v-select :items="deliverySpec.options" :label="deliverySpec.name" outlined v-model="selectedDeliverySpec"
            item-text="alternateName" item-value="id"></v-select>
          <div v-for="(spec, index) in otherSpecs" v-bind:key="index">
            <v-select :items="spec.options" :label="spec.name" outlined v-model="selectedOtherSpec[index]"
              item-text="name" item-value="id"></v-select>
          </div>
          <!-- <v-textarea label="File" type="text" hide-details="auto" outlined class="mb-6" v-model="imageLinks"
            placeholder="Link file. Apabila lebih dari satu link, pisahkan dengan simbol koma"></v-textarea> -->
            <v-textarea label="Alamat Pengiriman" type="text" hide-details="auto" outlined class="mb-6"
            v-model="address"></v-textarea>
            
          <v-textarea label="Catatan" type="text" hide-details="auto" outlined class="mb-6"
            v-model="notes"></v-textarea>
          <br />
          <hr />
          <p class="product-description my-4" v-if="!isAbleToCalculateFinalPrice">
            Pilih spesifikasi produk untuk melihat harga akhir
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Harga Produk: <b>{{ totalProductPrice }}</b>
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Biaya Pengiriman: <b>{{ totalDeliveryPrice }}</b>
          </p>
          <p class="product-description my-4" v-if="isAbleToCalculateFinalPrice">
            Total: <b>{{ totalPrice }}</b>
          </p>
          <v-btn class="main-button mx-2" v-observe-visibility="(isVisible, entry) =>
            visibilityChanged(isVisible, entry, 'fade-right')
            " @click="createOrder">Add To Cart</v-btn>
          <v-alert v-if="alertMessage" type="error" class="p-4 mt-4" >
            {{ alertMessage }}
          </v-alert>
          <v-dialog v-model="dialog" persistent max-width="50%">
            <v-card>
              <v-card-title class="standerd-light">Pesanan Berhasil Ditambahkan ke Keranjang!</v-card-title>
              <v-card-text class="standerd-light">Klik OK untuk melanjutkan.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="loginDialog" persistent max-width="50%">
            <v-card>
              <v-card-title class="standerd-light">Login Untuk Menyimpan Pesanan</v-card-title>
              <v-card-text class="standerd-light">Untuk menyimpan pesanan Anda di keranjang, harap login terlebih
                dahulu.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="handleDialogOkLogin">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <FooterSection />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
  name: "ProductDetailPage",
  components: {
    FooterSection,
  },
  data() {
    return {
      files: [],
      product: {},
      alertMessage: null,

      priceSpec: [],
      deliverySpec: [],
      otherSpecs: [],

      checkoutData: {},
      qty: 0,
      selectedPriceSpec: "",
      selectedDeliverySpec: "",
      selectedOtherSpec: [],
      notes: "",
      address: "",
      imageLinks: "",
      dialog: false,
      loginDialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts"]),
    isAbleToCalculateFinalPrice() {
      return (this.selectedPriceSpec != "") && (this.selectedDeliverySpec != "") && (this.qty != 0)
    },
    totalProductPrice() {
      const selectedPriceSpecOption = this.priceSpec.options.find(opt => opt.id === this.selectedPriceSpec)
      const pricePerQty = selectedPriceSpecOption.price
      return this.formatRupiah(pricePerQty * this.qty)
    },
    totalDeliveryPrice() {
      const selectedDeliverySpecOption = this.deliverySpec.options.find(opt => opt.id === this.selectedDeliverySpec)
      const deliveryPrice = selectedDeliverySpecOption.price
      return this.formatRupiah(deliveryPrice)
    },
    totalPrice() {
      const selectedPriceSpecOption = this.priceSpec.options.find(opt => opt.id === this.selectedPriceSpec)
      const selectedDeliverySpecOption = this.deliverySpec.options.find(opt => opt.id === this.selectedDeliverySpec)
      const pricePerQty = selectedPriceSpecOption.price
      const deliveryPrice = selectedDeliverySpecOption.price
      return this.formatRupiah((pricePerQty * this.qty) + deliveryPrice)
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    formatRupiah(num) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
      }).format(num)
    },
    getImageUrl(path) {
      if (path.includes("http")) {
        return path
      }
      return "https://firebasestorage.googleapis.com/v0/b/cetaklabel2024-ad87c.appspot.com/o/" + path + "?alt=media"
    },
    resetForms() {
      this.checkoutData = {}
      this.qty = 0
      this.selectedPriceSpec = ""
      this.selectedDeliverySpec = ""
      this.selectedOtherSpec = []
      this.notes = "",
        this.imageLinks = ""
    },
    encodeNotes(){
      return this.address + "#####" + this.notes
    },
    async createOrder() {
      if (this.token === '') {
        this.loginDialog = true;
        return;
      }

      console.log("selectedPriceSpec", this.selectedPriceSpec)
      const handleOtherSpecSelection = this.otherSpecs.map((it, index) => ({
        specificationId: it.id,
        specificationOptionId: this.selectedOtherSpec[index]
      }));
      const createOrderData = {
        productId: this.product.id,
        imageUploads: [],
        // imageUploads: this.imageLinks.split(",").map(link => link.trim()),
        notes: this.encodeNotes(),
        qty: Number(this.qty),
        deliveryFeeSpecification: {
          specificationId: this.deliverySpec.id,
          specificationOptionId: this.selectedDeliverySpec
        },
        specifications: handleOtherSpecSelection,
        priceSpecification: {
          specificationId: this.priceSpec.id,
          specificationOptionId: this.selectedPriceSpec
        }
      }
      this.executeCreateOrder(createOrderData)
    },
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    async getProduct() {
      const url = `${this.baseUrl}/product/${this.$route.params.id}`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.statusCode == 200) {
          const responseData = response.data.data
          const startPrice = responseData.specifications
  .filter(spec => spec.type === "PRICE" && spec.options && spec.options.length > 0)
  .map(spec => spec.options.sort((a, b) => b.price - a.price)[0].price)[0] || null;
          this.product = {
            ...responseData,
            startFrom: startPrice
          }

          this.priceSpec = this.product.specifications.find(spec => spec.type === "PRICE");
          this.deliverySpec = this.product.specifications.find(spec => spec.type === "DELIVERY_FEE");
          this.otherSpecs = this.product.specifications.filter(spec => spec.type != "DELIVERY_FEE" && spec.type != "PRICE")

          const revampedDeliverySpecName = this.deliverySpec.options.map((it) => ({
            ...it,
            alternateName: `${it.name} ${this.formatRupiah(it.price) } (Qty: ${it.fromQuantity} - ${it.toQuantity})`
          }));
          this.deliverySpec = {
            ...this.deliverySpec,
            options: revampedDeliverySpecName
          };

          const revampedPriceSpecName = this.priceSpec.options.map((it) => ({
            ...it,
            alternateName: it.name + " (" + this.formatRupiah(it.price) + ")"
          }));
          this.priceSpec = {
            ...this.priceSpec,
            options: revampedPriceSpecName
          };

          this.selectedOtherSpec = new Array(this.otherSpecs.length).fill("");
        }
      } catch (error) {
        console.error('Error getting product detail:', error);
      }
    },
    async executeCreateOrder(createOrderData) {
      console.log("createOrderData", createOrderData)
      const url = `${this.baseUrl}/order`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
        'Authorization': `token ${this.token}`
      };

      try {
        const response = await axios.post(url, createOrderData, { headers });
        console.log('Create order result:', response.data);
        if (response.data.statusCode == 201) {
          this.dialog = true;
        } else {
          this.alertMessage = response.data.message;
        }
      } catch (error) {
        console.error('Error create checkout:', error);
        this.alertMessage = "Error!";
      }
    },
    handleDialogOk() {
      this.dialog = false; // Hide the dialog
      this.$router.push('/cart');
    },
    handleDialogOkLogin() {
      this.loginDialog = false; // Hide the dialog
      this.$router.push('/login');
    },
  }
};
</script>

<style>
.display-image {
  /* height: 100%; */
}

.photos-container {
  /* overflow: hidden !important; */
  max-height: 85dvh;
}

.detail-container {
  overflow: auto;
  max-height: 85dvh;
}

.product-thumbnail {
  background-color: green;
}

.thumbnail-image {
  height: 6vw;
  width: 6vw;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  border: 0.5px #131313 solid;
}

.detail-container {
  background-color: #f8f8f8 !important;
  border-left: 1px #131313 solid;
}

.detail-text {
  color: #131313 !important;
}

.product-description {
  font-family: "Standerd", sans-serif;
  font-weight: 300;
  font-size: clamp(18px, 1.3vw, 36px) !important;
}

.upload-container {
  border: 0.8px #131313 solid;
  border-radius: 5px;
}

.image-container {
  display: flex;
  /* Enables flexbox */
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  height: 100%;
  /* Full height to match the carousel item */
}

.responsive-image {
  max-width: 100%;
  /* Maximum width is the container width */
  max-height: 85dvh;
  /* Maximum height to match the carousel */
  object-fit: contain;
  /* Ensures the image is fully visible */
}

</style>

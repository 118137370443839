import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        defaultTheme: 'myCustomTheme',
        themes: {
          myCustomTheme: {
            variables: {
              fontFamily: 'Standerd, sans-serif',
            },
          },
        },
      },
});

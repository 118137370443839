<template>
    <v-container>
        <v-row class="cart-container">
            <v-col cols="12" md="8" class="cart-list nospace">
                <div class="cart-header standerd-light py-5 px-5">
                    <p class="product-title" v-if="!isCart">Order #{{ checkoutData.refNumber }}</p>
                    <p class="product-title" v-if="isCart">My Cart</p>
                </div>
                <div class="cart-item d-flex py-8 px-10" v-for="(item, index) in items" v-bind:key="index">

                    <v-row align="center" class="mb-2">
                        <p class="cart-item-number standerd-bold pr-10 product-title">{{ index + 1 }}</p>
                    </v-row>

                    <div class="cart-item-detail">
                        <v-row align="center" class="mb-2">
                            <v-col cols="11" class="product-title">
                                <b>{{ item.productName }}</b>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-end">
                                <v-btn icon color="red" @click="deleteOrder(item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <div class="spacer-1"></div>
                        <div class="d-flex flex-wrap cart-item-specs">
                            <v-text-field v-for="(spec, specIdx) in item.specifications" v-bind:key="specIdx"
                                :label="spec.specificationName" :rules="rules" :value="spec.specificationOptionName"
                                class="ml-4" outlined disabled></v-text-field>
                            <v-text-field label="Catatan" :value="item.notes" outlined disabled
                                class="ml-4"></v-text-field>
                            <v-text-field label="Alamat Pengiriman" :value="item.address" outlined disabled
                                class="ml-4"></v-text-field>
                            <v-text-field label="Qty" :value="item.qty" outlined disabled class="ml-4"></v-text-field>
                            <v-text-field label="Biaya Pengiriman" :value="formatRupiah(item.deliveryFee)" outlined
                                disabled class="ml-4"></v-text-field>
                            <v-text-field label="Total Produk" :value="formatRupiah(item.price)" outlined disabled
                                class="ml-4"></v-text-field>
                        </div>
                        <div class="spacer-1"></div>
                        <p class="cart-item-title standerd-semibold pt-2 text-right product-description">
                            <!-- Total Produk: <b>{{ formatRupiah(item.price * item.qty) }}</b> -->
                            Total: <b>{{ formatRupiah(Number(item.price) + Number(item.deliveryFee)) }}</b>
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" class="cart-summary nospace">
                <v-expansion-panels class="panels" v-model="panel">
                    <v-expansion-panel expand v-model="panel">
                        <v-expansion-panel-header id="summary-header"
                            class="cart-header no-border-left-right py-5 px-5 standerd-light">
                            <p class="product-title">Summary</p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="box-bottom-border">
                            <div class="summary-box py-5 px-5">
                                <div class="spacer-3"></div>
                                <div class="d-flex summary-item justify-space-between mb-3"
                                    v-for="(item, index) in items" v-bind:key="index">
                                    <p class="product-description">
                                        {{ item.productName }}
                                    </p>
                                    <p class="product-description"><b>{{ formatRupiah(Number(item.price) +
                                        Number(item.deliveryFee))
                                            }}</b></p>
                                </div>
                                <hr />
                                <div class="spacer-1"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="product-description">Total</p>
                                    <p class="product-description"><b>{{ formatRupiah(getTotalCheckoutPrice) }}</b></p>
                                </div>
                                <br />
                                <v-btn v-if="isCart" class="main-button mx-2" @click="submitCheckout"> Checkout</v-btn>
                                <v-dialog v-model="dialog" persistent max-width="50%">
                                    <v-card>
                                        <v-card-title class="standerd-light">Checkout Berhasil!</v-card-title>
                                        <v-card-text class="standerd-light">Silakan upload bukti pembayaran
                                            Anda</v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="panels no-border-top" v-if="!isCart" v-model="panel">
                    <v-expansion-panel v-model="panel" expand>
                        <v-expansion-panel-header id="summary-header"
                            class="cart-header no-border-left-right py-5 px-5 standerd-light">
                            <p class="product-title">Payment</p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="box-bottom-border">
                            <div class="summary-box py-5 px-5" v-if="!isAlreadyPaid">
                                <div class="spacer-3"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="standerd-light">
                                        Transfer dana ke nomor rekening dibawah ini
                                    </p>
                                </div>

                                <hr />
                                <div class="spacer-1"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="standerd-light">Bank</p>
                                    <p class="standerd-semibold">BCA</p>
                                </div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="standerd-light">Nomor Rekening</p>
                                    <p class="standerd-semibold">12345567</p>
                                </div>

                                <div class="spacer-3"></div>
                                <hr />
                                <div class="spacer-1"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="standerd-light">
                                        Unggah bukti pembayaran
                                    </p>
                                </div>
                                <div class="spacer-1"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <v-file-input label="File input" @change="onFileSelected"></v-file-input>
                                </div>

                                <v-alert v-if="paymentAlertMessage" type="error" class="p-4 mb-2" >
                                    {{ paymentAlertMessage }}
                                </v-alert>

                                <v-btn class="main-button mx-2" @click="uploadPaymentProof"> Submit</v-btn>
                                <v-dialog v-model="dialog" persistent max-width="50%">
                                    <v-card>
                                        <v-card-title class="standerd-light">Bukti Pembayaran Berhasil
                                            Diunggah!</v-card-title>
                                        <v-card-text class="standerd-light">Anda akan menerima email notifikasi setelah
                                            proses verifikasi
                                            selesai.</v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                            <div class="summary-box py-5 px-5" v-if="isAlreadyPaid">
                                <div class="spacer-3"></div>
                                <div class="d-flex summary-item justify-space-between mb-3">
                                    <p class="standerd-light">
                                        Pesanan sudah dibayar. Admin sedang memproses pesanan Anda.
                                    </p>
                                </div>


                                <hr />
                                <div class="spacer-3"></div>
                                <v-img class="mx-auto" :lazy-src="checkoutData.proofOfPayment" max-width="30vh"
                                    :src="getImageUrl(checkoutData.proofOfPayment)">
                                    <template v-slot:placeholder>
                                        <div class="d-flex align-center justify-center fill-height">
                                            <v-progress-circular color="grey-lighten-4"
                                                indeterminate></v-progress-circular>
                                        </div>
                                    </template>
                                </v-img>

                                <v-alert v-if="paymentAlertMessage" type="error" class="p-4 mb-2" >
                                    {{ paymentAlertMessage }}
                                </v-alert>

                                <v-alert v-if="paymentAlertMessage" type="success" class="p-4 mb-2" >
                                    {{ paymentAlertMessage }}
                                </v-alert>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <FooterSection />
    </v-container>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
    name: "OrderPage",
    components: {
    FooterSection,
  },
    data() {
        return {
            items: {},
            checkoutData: {},
            uploadPaymentProofData: {
                refNumber: '',
                proofOfPayment: ''
            },
            isCart: true,
            alertMessage: "",
            paymentAlertMessage: "",
            paymentSuccessMessage: "",
            panel: 0,
            dialog: false,
            selectedFile: null,
        };
    },
    computed: {
        ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts", "checkouts"]),
        getTotalCheckoutPrice() {
            return this.items.reduce((total, item) => {
                return total + (Number(item.price) + Number(item.deliveryFee));
            }, 0); // Initialize the total with 0
        },
        isAlreadyPaid() {
            return this.checkoutData.proofOfPayment != null
        },
    },
    mounted() {
        if (this.token === '') {
            this.$router.push('/login');
            return;
        }

        if (this.isCart) {
            this.getCart()
        } else {
            this.getCheckoutItemFromStore()
        }
    },
    methods: {
        getImageUrl(path) {
            return "https://firebasestorage.googleapis.com/v0/b/cetaklabel2024-ad87c.appspot.com/o/" + path + "?alt=media"
        },
        formatRupiah(num) {
            return new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
            }).format(num)
        },
        onFileSelected(file) {
            if (file != null) {
                this.selectedFile = file;
                this.uploadFile();
            } else {
                console.error('No file selected');
                this.alertMessage = 'Please select a file to upload.';
            }
        },
        prepareItemsForDisplay(items) {
            const res = items.map((it) => {
                const notesSplit = it.notes.split("#####");
                return {
                    ...it,
                    notes: notesSplit[1] ? notesSplit[1] : '-', // Ensure there is a second part; if not, use an empty string
                    address: notesSplit[0] ? notesSplit[0] : '-'
                };
            });
            console.log("prepareItemsForDisplay", res)
            return res;
        },
        getCheckoutItemFromStore() {
            const targetCheckout = this.checkouts.find(it => it.id === this.$route.params.id)
            const targetCheckoutItems = this.prepareItemsForDisplay(targetCheckout.checkoutItems.map(it => it.order))
            this.checkoutData = targetCheckout
            this.items = targetCheckoutItems
            this.uploadPaymentProofData.refNumber = targetCheckout.refNumber
        },
        async getCheckoutItems() {
            const url = `${this.baseUrl}/checkout`;
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.get(url, { headers });
                if (response.data.statusCode == 200) {
                    this.checkoutItems = this.prepareItemsForDisplay(response.data.data)
                }
            } catch (error) {
                console.error('Error getting list checkouts:', error);
            }
        },
        async deleteOrder(orderId) {
            const url = `${this.baseUrl}/order/${orderId}`;
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'application/json',
                'Authorization': `token ${this.token}`
            };

            try {
                const response = await axios.delete(url, { headers });
                if (response.data.statusCode == 200) {
                    this.items = this.items.filter((it) => it.id != orderId)
                }
            } catch (error) {
                console.error('Error deleting order:', error);
            }
        },
        async uploadPaymentProof() {
            if (this.uploadPaymentProofData.proofOfPayment === "") {
                this.paymentAlertMessage = "Mohon masukkan bukti pembayaran"
            } else {
                const url = `${this.baseUrl}/checkout/upload-proof-of-payment`;
                const headers = {
                    'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                    'Content-Type': 'application/json',
                    'Authorization': `token ${this.token}`
                };

                try {
                    const response = await axios.post(url, this.uploadPaymentProofData, { headers });
                    console.log('Submit payment proof result:', response.data);
                    if (response.data.statusCode === 201) {
                        this.dialog = true;
                    } else {
                        this.paymentAlertMessage = response.data.message;
                    }
                } catch (error) {
                    console.error('Error submit payment proof:', error);
                    this.paymentAlertMessage = "Error!";
                }
            }
        },
        async getCart() {
            const url = `${this.baseUrl}/order/cart`;
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'application/json',
                'Authorization': `token ${this.token}`
            };

            try {
                const response = await axios.get(url, { headers });
                if (response.data.statusCode == 200) {
                    this.items = this.prepareItemsForDisplay(response.data.data)

                    console.log("items", this.items)
                }
            } catch (error) {
                console.error('Error getting cart:', error);
            }
        },
        async submitCheckout() {
            const url = `${this.baseUrl}/checkout`;
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'application/json',
                'Authorization': `token ${this.token}`
            };
            const submitCheckoutData = {
                orderIds: this.items.map(it => it.id)
            }

            try {
                const response = await axios.post(url, submitCheckoutData, { headers });
                console.log('Submit checkout:', response.data);
                if (response.data.statusCode == 201) {
                    this.dialog = true;
                } else {
                    this.alertMessage = response.data.message;
                }
            } catch (error) {
                console.error('Error submit checkout:', error);
                this.alertMessage = error.response.data.message;
            }
        },
        handleDialogOk() {
            this.dialog = false; // Hide the dialog
            this.$router.push("/my-order");
        },
        async uploadFile() {
            const formData = new FormData();
            formData.append('file', this.selectedFile);

            const url = `${this.baseUrl}/utils/file-upload`; // Ensure baseUrl is correctly defined in your data or computed properties
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'multipart/form-data', // This will be set correctly by the browser when FormData is used
                'Authorization': `token ${this.token}`
            };

            try {
                const response = await axios.post(url, formData, { headers });
                console.log('File upload response:', response.data);
                if (response.data.statusCode === 200) {
                    this.uploadPaymentProofData.proofOfPayment = response.data.data.path
                } else {
                    this.paymentAlertMessage = response.data.message;
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                this.paymentAlertMessage = error.response ? error.response.data.message : 'Error uploading file';
            }
        }
    }
};
</script>

<style>
.cart-container {
    /* background-color: red; */
    /* max-height: 84vh; */
    /* overflow: hidden !important; */
}

.thumbnail-image {
    height: 10vw;
    width: 10vw;
}

.nospace {
    padding: 0px !important;
    margin: 0px !important;
}

.cart-header {}
</style>
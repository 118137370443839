<template>
  <div
    class="d-flex align-center client-wrapper"
    :class="{
      'px-8 py-4': $vuetify.breakpoint.mdAndUp,
      'px-2 py-2': !$vuetify.breakpoint.mdAndUp,
    }"
  >
    <span class="main-title selino py-4 px-4"></span>
  </div>
</template>

<script>
export default {
  name: "ClientSection",
};
</script>

<style>
.client-wrapper {
  background-color: #e7f0ff;
  border-top: 1px #114aa4 solid;
  border-bottom: 1px #114aa4 solid;
}
</style>

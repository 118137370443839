<template>
  <v-app class="app">
    <!-- <v-app-bar app color="dark" dark>
      <div class="d-flex align-center"></div>

      <v-spacer></v-spacer>

      <v-icon>mdi-account</v-icon>
      <v-icon>mdi-magnify</v-icon>
      <v-icon>mdi-cart-outline</v-icon>
    </v-app-bar> -->

    <v-main>
      <div class="discount-bar py-4 text-center standerd-semibold">
        <h1>cetaklabel.id</h1>
        <!-- <div class="sliding-text">cetaklabel.id</div> -->
      </div>
      <!-- wide app bar -->
      <div class="dark-bg py-4 justify-space-between d-none d-md-flex wide-app-bar">
        <!-- logo -->
        <div class="align-self-center px-4">
          <h3 class="khula-semibold hidden-element">cetaklabel.id</h3>
        </div>
        <!-- menu -->
        <div class="d-flex align-self-center">
          <span class="standerd-light mx-4"><router-link to="/" exact>HOME</router-link>
          </span>

          <router-link to="/products" exact>
            <span class="standerd-light mx-4">PRODUCTS</span>
          </router-link>

          <router-link to="/about-us" exact>
            <span class="standerd-light mx-4">ABOUT US</span>
          </router-link>
          <!-- <span class="standerd-light mx-4">BLOGS</span> -->
        </div>
        <!-- icons -->
        <div class="d-flex align-self-center px-4">
          <!-- <router-link to="/login" exact>
            <v-icon class="appbar-icon mx-2">mdi-account</v-icon>
          </router-link> -->

          <router-link to="/cart" exact>
            <v-icon class="appbar-icon mx-2">mdi-cart-outline</v-icon>
          </router-link>
          <router-link to="/my-order" exact>
          <v-icon class="appbar-icon mx-2">mdi-package</v-icon>
          </router-link>
        </div>
      </div>
      <!-- small app bar -->
      <div class="small-app-bar dark-bg d-flex py-4 justify-space-between d-md-none">
        <!-- logo -->
        <div class="align-self-center px-4">
          <h3 class="khula-semibold hidden-element">cetaklabel.id</h3>
        </div>
        <!-- menu -->
        <div class="mx-2">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="smallbar-btn" v-on="on">
                <v-icon class="appbar-icon mx-2">mdi-menu</v-icon></v-btn>
            </template>
            <v-list class="smallbar">
              <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/" exact>Home
                  </router-link><br /></v-list-tile-title>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/products"
                    exact>Products
                  </router-link><br /></v-list-tile-title>
              </v-list-tile>

              <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/about-us"
                    exact>About Us
                  </router-link><br /></v-list-tile-title>
              </v-list-tile>

              <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/cart"
                    exact>Carts
                  </router-link><br /></v-list-tile-title>
              </v-list-tile>

              <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/my-order"
                    exact>Orders
                  </router-link><br /></v-list-tile-title>
              </v-list-tile>


              <!-- <v-list-tile>
                <v-list-tile-title class="pa-2 mb-4 standerd-light smallbar-menu"><router-link to="/account" exact>Account
                  </router-link><br /></v-list-tile-title>
              </v-list-tile> -->
            </v-list>
          </v-menu>
        </div>
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "App",
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts"]),
  },

  data: () => ({
    items: [
      { title: "Home", redirect: "/" },
      { title: "About Us", redirect: "/" },
      { title: "Products", redirect: "/products" },
      { title: "Cart", redirect: "/cart" },
      { title: "Order", redirect: "/cart" },
    ],
  }),
  methods: {
    ...mapActions('auth', ['setProducts', 'setFeaturedProducts']),
    async getProducts() {
      const url = `${this.baseUrl}/product`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.statusCode == 200) {
          const products = response.data.data;
          const featuredProducts = products.filter((it) => it.isFeatured);
          this.setProducts(products)
          this.setFeaturedProducts(featuredProducts)
        }
      } catch (error) {
        console.error('Error getting list product:', error);
      }
    },
  },

  mounted() {
    const link = document.createElement("link");
    link.href =
      "https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    // this.getProducts()
  },
};
</script>

<style>
/* .wide-app-bar {
  background-color: red !important;
}

.small-app-bar {
  background-color: red !important;
} */

.discount-bar {
  background-color: #baceed !important;
}

.standerd-bold {
  font-family: "Standerd", sans-serif;
  font-weight: 700;
}

.standerd-semibold {
  font-family: "Standerd", sans-serif;
  font-weight: 600;
}

.standerd-light {
  font-family: "Standerd", sans-serif;
  font-weight: 300;
}

.selino {
  font-family: "Arial", sans-serif;
  font-weight: normal;
}

.dark-bg {
  background-color: #114aa4 !important;
  box-shadow: none !important;
  color: white !important;
}

.appbar-icon {
  color: white !important;
  cursor: pointer;
}

.app {
  margin: 0px !important;
  padding: 0px !important;
}

.container {
  margin: 0px !important;
  padding: 0px !important;
  max-width: 100vw !important;
}

.row {
  margin: 0px !important;
}

.col {
  padding: 0px !important;
  padding: 0px !important;
}

.spacer-3 {
  min-height: 3vw;
}

.spacer-1 {
  min-height: 1vw;
}

.image-hover:hover {
  opacity: 0.8;
  /* Reduced opacity on hover */
}

.image-hover {
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.5s ease;
  /* Smooth transition for opacity change */
}

.text-hover {
  color: initial;
  /* Default text color */
  transition: color 0.3s ease;
  /* Smooth transition for color change */
}

.text-hover:hover {
  color: #114aa4;
  /* Color changes to #114AA4 on hover */
}

.panels {
  border-radius: 0px !important;
  z-index: unset !important;
}

.v-expansion-panel--active {
  border-radius: 0px !important;
}

.main-title {
  color: #114aa4;
  font-size: clamp(22px, 2.2vw, 60px) !important;
}

.main-title-outline {
  color: #114aa4;
  font-size: 2.1vw;
  border-radius: 1000px;
  border: 1px #114aa4 solid;
  padding: 0.5vw 1.2vw;
  width: fit-content;
  background-color: white;
}

.smallbar-menu {
  cursor: pointer;
}

.smallbar-menu:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #114aa4 !important;
}

.smallbar-btn {
  background-color: #1C5CC1 !important;
}

.basic-text {
  font-size: clamp(20px, 1.5vw, 40px) !important;
}

.border-all {
  border: 1px #114aa4 solid;
}

.border-top {
  border-top: 1px #114aa4 solid;
}

.border-left {
  border-left: 1px #114aa4 solid;
}

.border-right {
  border-right: 1px #114aa4 solid;
}

.router-link {
  color: unset !important;
  text-decoration: unset !important;
}

.hidden-element {
  display: none !important;
}

.text-blue {
  color: #0e4195;
}

.pagination {
  cursor: pointer;
}

.pagination:hover {
  color: #114aa4;
}

.wide-app-bar a {
  color: unset !important;
  text-decoration: unset;
}

.smallbar a {
  color: unset !important;
  text-decoration: unset;
}

.wide-app-bar a:hover {
  text-decoration: underline;
}

.router-link-exact-active {
  color: red !important;
}

.appbar-icon:hover {
  color: #baceed !important;
}

/* .sliding-text {
  animation: slide 15s ease infinite;
  white-space: nowrap;
} */
@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.fade-left {
  animation: fadeLeft 1s ease-in-out;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-right {
  animation: fadeRight 1s ease-in-out;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-top {
  animation: fadeTop 1s ease-in-out;
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-bottom {
  animation: fadeBottom 1s ease-in-out;
}

@keyframes fadeBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade {
  animation: fade 1.5s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bounce {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  70% {
    transform: translateX(10px);
    /* Move slightly to the right for the bounce effect */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    /* Return to the original position */
  }
}

.khula-light {
  font-family: "Khula", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.khula-regular {
  font-family: "Khula", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.khula-semibold {
  font-family: "Khula", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.khula-bold {
  font-family: "Khula", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.khula-extrabold {
  font-family: "Khula", sans-serif;
  font-weight: 800;
  font-style: normal;
}
</style>

<template>
    <v-container>
        <v-container fluid class="card-in-image-container">
        <v-layout align-center justify-center>
            <v-flex xs12>
                <div class="absolute-center px-12 py-12 d-flex flex-column text-center"
                    :class="{ 'halfscreen': $vuetify.breakpoint.mdAndUp }">
                    <!-- Content -->
                    <h1 class="khula-bold">cetaklabel.id</h1>
                    <p class="standerd-light">Semua tentang stiker dan label.</p>
                    <p class="standerd-bold py-4">Reset Password</p>
                    <v-text-field label="Email" v-model="forgotPasswordModel.email" outlined></v-text-field>
                    <v-text-field label="Kode OTP" v-model="forgotPasswordModel.otp" outlined></v-text-field>
                    <v-text-field label="Password Baru" v-model="forgotPasswordModel.password" type="password"
                        outlined></v-text-field>
                    <v-text-field label="Konfirmasi Password" v-model="forgotPasswordModel.confirmationPassword"
                        type="password" outlined></v-text-field>
                    <br />
                    <v-alert v-if="alertMessage" type="error" class="p-4">
                        {{ alertMessage }}
                    </v-alert>
                    <div class="align-center">
                        <v-btn class="main-button mx-2" @click="forgotPassword"> Reset Password </v-btn>
                    </div>
                    <!-- Login Success Dialog -->
                    <v-dialog v-model="dialog" persistent max-width="50%">
                        <v-card>
                            <v-card-title class="standerd-light">Password Anda Berhasil Diubah!
                            </v-card-title>
                            <v-card-text class="standerd-light">Silakan login menggunakan password baru Anda.
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
    <FooterSection />
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
    components: {
    FooterSection,
  },
    data() {
        return {
            forgotPasswordModel: {
                email: '',
                password: '',
                confirmationPassword: '',
                otp: ''
            },
            alertMessage: null,
            dialog: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["apiKey", "token", "baseUrl"]),
    },
    methods: {
        async forgotPassword() {
            if (this.forgotPasswordModel.password != this.forgotPasswordModel.confirmationPassword) {
                this.alertMessage = "Konfirmasi password salah!"
                return;
            }
            if (this.forgotPasswordModel.email === '') {
                this.alertMessage = "Mohon lengkapi email"
                return;
            }
            if (this.forgotPasswordModel.otp === '') {
                this.alertMessage = "Mohon lengkapi kode OTP"
                return;
            }
            if (this.forgotPasswordModel.password === '') {
                this.alertMessage = "Mohon lengkapi password baru"
                return;
            }
            const url = `${this.baseUrl}/auth/reset-password`;
            const headers = {
                'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.post(url, this.forgotPasswordModel, { headers });
                console.log('Reset password result:', response.data);
                if (response.data.statusCode == 200) {
                    this.dialog = true;
                } else {
                    this.alertMessage = response.data.message;
                }
            } catch (error) {
                console.error('Error reset password:', error);
                this.alertMessage = error.response.data.message;
            }
        },
        handleDialogOk() {
            this.dialog = false; // Hide the dialog
            this.$router.push('/login'); // Navigate to the homepage
        },
    }
}
</script>
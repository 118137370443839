<template>
  <div><ProductListSection /><FooterSection /></div>
</template>

<script>
import ProductListSection from "../components/ProductListSection";
import FooterSection from "../components/FooterSection";
export default {
  components: {
    ProductListSection,
    FooterSection,
  },
};
</script>

<template>
  <div>
    <HomeBanner />
    <ClientSection />
    <BestSellerSection />
    <ProductListSection />
    <ValuesSection />
    <GallerySection />
    <FooterSection />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeBanner from "../components/HomeBanner";
import ClientSection from "../components/ClientSection";
import BestSellerSection from "../components/BestSellerSection";
import ProductListSection from "../components/ProductListSection";
import ValuesSection from "../components/ValuesSection";
import GallerySection from "../components/GallerySection";
import FooterSection from "../components/FooterSection";

export default {
  name: "HomePage",
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products"]),
  },
  components: {
    HomeBanner,
    ClientSection,
    BestSellerSection,
    ProductListSection,
    ValuesSection,
    GallerySection,
    FooterSection,
  },
  methods: {

  },
  mounted() {
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner border-top border-left border-right"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('../assets/main_bg_backup.png')}},[_c('div',{staticClass:"d-flex align-center banner-text selino mx-8 my-6"},[_c('div',{staticClass:"banner-text-wrapper"},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
            (isVisible, entry) => _vm.visibilityChanged(isVisible, entry, 'fade')
          ),expression:"\n            (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')\n          "}],staticClass:"non-outlined-title"},[_vm._v("We Use")]),_c('div',{staticClass:"d-flex"},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
              (isVisible, entry) =>
                _vm.visibilityChanged(isVisible, entry, 'fade-top')
            ),expression:"\n              (isVisible, entry) =>\n                visibilityChanged(isVisible, entry, 'fade-top')\n            "}],staticClass:"outlined-title"},[_vm._v("Best")]),_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
              (isVisible, entry) =>
                _vm.visibilityChanged(isVisible, entry, 'fade-bottom')
            ),expression:"\n              (isVisible, entry) =>\n                visibilityChanged(isVisible, entry, 'fade-bottom')\n            "}],staticClass:"outlined-title"},[_vm._v("Quality")])]),_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
            (isVisible, entry) => _vm.visibilityChanged(isVisible, entry, 'fade')
          ),expression:"\n            (isVisible, entry) => visibilityChanged(isVisible, entry, 'fade')\n          "}],staticClass:"non-outlined-title"},[_vm._v("Materials")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-container fluid class="card-in-image-container">
    <v-layout align-center justify-center>
      <v-flex xs12>
        <div class="absolute-center px-12 py-12 d-flex flex-column text-center"
          :class="{ 'halfscreen': $vuetify.breakpoint.mdAndUp }">
          <!-- Content -->
          <h1 class="khula-bold">cetaklabel.id</h1>
          <p class="standerd-light">Semua tentang stiker dan label.</p>
          <p class="standerd-bold py-4">Reset Password</p>
          <v-text-field label="Email" v-model="email" outlined></v-text-field>
          <p class="standerd-light">
            Kode OTP akan dikirimkan ke email Anda
          </p>
          <br />
          <v-alert v-if="alertMessage" type="error" class="p-4">
            {{ alertMessage }}
          </v-alert>
          <div class="align-center">
            <v-btn class="main-button mx-2" @click="requestOtp"> Kirim OTP </v-btn>
          </div>
          <!-- Login Success Dialog -->
          <v-dialog v-model="dialog" persistent max-width="50%">
            <v-card>
              <v-card-title class="standerd-light">OTP Berhasil Dikirimkan!
              </v-card-title>
              <v-card-text class="standerd-light">Mohon cek email Anda.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="handleDialogOk">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
  <FooterSection />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FooterSection from "../components/FooterSection";

export default {
  data() {
    return {
      email: '',
      alertMessage: null,
      dialog: false
    };
  },
  components: {
    FooterSection,
  },
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl"]),
  },
  methods: {
    async requestOtp() {
      if(this.email == '') {
        this.alertMessage = "Mohon lengkapi email"
        return;
      }
      const url = `${this.baseUrl}/auth/request-otp`;
        const headers = {
          'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
          'Content-Type': 'application/json',
        };
        const requestOtpData = {
          'email': this.email,
        }

        try {
          const response = await axios.post(url, requestOtpData, { headers });
          console.log('User login:', response.data);
          if (response.data.statusCode == 200) {
            this.dialog = true
          } else {
            this.alertMessage = response.data.message;
          }
        } catch (error) {
          console.error('Error creating product:', error);
          this.alertMessage = error.response.data.message;
        }
    },
    handleDialogOk() {
      this.dialog = false; // Hide the dialog
      this.$router.push('/confirm-forgot-password'); // Navigate to the homepage
    },
  }
}
</script>

<style>
.card-in-image-container {
  background-color: #f8f8f8;
  height: 100vh;
  /* corrected 100dvh to 100vh for valid CSS */
  overflow: hidden;
  background-image: url('../assets/main_bg_backup.png');
  /* Path to your background image */
  background-size: cover;
  /* Cover the entire container */
  background-position: center;
  /* Center the background image */
  background-repeat: repeat;
  /* No repeating the background */
}

.absolute-center {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  border: 1px #e0dfe4 solid;
  box-shadow: 0 0 100px 0 rgba(14, 65, 148, 0.2);
}

.halfscreen {
  width: 50vw;
}
</style>


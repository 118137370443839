<template>
  <div
    class="d-flex flex-column justify-center"
    :class="{
      'px-8 py-4': $vuetify.breakpoint.mdAndUp,
      'px-8 py-2': !$vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-row class="d-flex" justify="space-between">
      <div
        class="main-title main-title-outline selino mt-10 mb-10 align-self-start"
        v-observe-visibility="
          (isVisible, entry) => visibilityChanged(isVisible, entry, 'bounce')
        "
      >
        OUR PRODUCTS
      </div>
      <div>
        <v-autocomplete
          v-model="search"
          :items="itemNames"
          label="Search a product ..."
          class="search-input standerd-bold mt-10 mr-4"
          @input="onValueChange"
        ></v-autocomplete>
      </div>
    </v-row>

    <v-row class="d-flex">
      <ProductCard :smSize="6" :mdSize="3" :lgSize="3" :isBestSeller="false" 
        v-for="(product, index) in visibleProducts"
        :key="index" 
        :productName="product.name" 
        :startFrom="product.startFrom" 
        :images="product.images" 
        :productId="product.id"
      />
    </v-row>

    <v-row class="align-self-center">
      <p class="standerd-light mt-10 basic-text align-self-center">
        Page {{ currentPage }} of {{ pageCount }}
      </p>
    </v-row>

    <v-row class="align-self-center mb-10">
      <p
        class="standerd-light mt-10 basic-text mr-10 pagination"
        @click="showPrev"
      >
        <u>Prev</u>
      </p>
      <p class="standerd-light mt-10 basic-text pagination" @click="showNext">
        <u>Next</u>
      </p>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ProductCard from "../components/ProductCard";

export default {
  name: "ProductListSection",
  data() {
    return {
      filteredItems: [],
      items: [],
      itemNames: [],
      currentPage: 1,
      search: ""
    };
  },
  components: {
    ProductCard,
  },
  computed: {
    ...mapGetters("auth", ["apiKey", "token", "baseUrl", "products", "featuredProducts"]),
    pageSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 8 : 2;
    },
    pageCount() {
      return Math.ceil(this.filteredItems.length / this.pageSize);
    },
    visibleProducts() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredItems.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    onValueChange() {
      if (this.search) {
        this.filteredItems = this.items.filter(it =>
          it.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        this.filteredItems = this.items;
      }
      this.currentPage = 1; // Reset to first page after search
    },
    visibilityChanged(isVisible, entry, animationType) {
      let domElement = entry.target;
      if (isVisible) {
        domElement.classList.add(animationType);
      } else {
        domElement.classList.remove(animationType);
      }
    },
    showNext() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    showPrev() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async getProducts() {
      const url = `${this.baseUrl}/product`;
      const headers = {
        'Api-key': this.apiKey,
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.statusCode === 200) {
          const products = response.data.data.map((product) => ({
            ...product,
            firstImage: product.images[0],
            secondImage: product.images.length > 1 ? product.images[1] : product.images[0],
            startFrom: product.specifications
              .filter(spec => spec.type === "PRICE" && spec.options && spec.options.length > 0)
              .map(spec => spec.options[0].price)[0] || null // Map to get all prices and take the first, fallback to null if none
          }));
          this.items = products;
          this.filteredItems = products;
          this.itemNames = this.items.map(it => it.name);
        }
      } catch (error) {
        console.error('Error getting list product:', error);
      }
    },
  },
};
</script>

<style>
.search-input {
  width: clamp(150px, 50vw, 500px) !important;
  padding: 0vw 3vw;
}
</style>
